.div-footer-img{
    width: 15rem;
    margin-top: 1rem;
}
  
.footer{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background-color: #eeeeee;
}

.footer .links-footer a {
    font-size: 1.2rem !important;
}

.texto-footer{
    background: #616D5F;
    width: 100%;
    text-align: center;
    color: #fff;
    padding: 1rem 0.5rem 1rem 0.5rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}