.background-green{
    background-color: #616D5F;
    width: 100%;
}

.background-white{
    background-color: #FFF;
    width: 100%;
    margin: 2rem 0 7rem 0;
}

.apresentation-component, .photos-component{
    background: #616D5F;
    color: #FFF;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.endereco-component{
    background: #FFF;
    color: #616D5F;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.logo-nl{
    font-family: "Brainly Script", regular;
    font-weight: 400;
}

.text{
    text-align: center;
}

.text p, h5 {
    font-size: 2rem;
}

.text h5{
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.text p{
    font-family: "Roboto", sans-serif;
    font-weight: 100;
    font-style: normal;
}
.text h2 {
    font-size: 4.5rem;
    font-family: "Brainly Script", regular;
    font-optical-sizing: auto;
    font-weight: 200;
    font-style: normal;
}

.contador h3 {
    font-family: "Roboto", sans-serif;
    font-weight: 300;
    font-style: normal;
}

.text h2, h5, p{
    margin: 1.5rem 0 1.5rem 0;
}

.pick{
    margin: 1rem 0 1rem 0;
    padding: 1rem;
}

.pick img{
    border-radius: 1rem;
    height: 30rem !important;
}
.pick-init img{
    height: 28rem !important;
}
.contador{
    color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 2rem;
}

.contador h2{
    font-weight: 100;
}

.text-and-pick{
    display: flex;
    align-items: center;
    color: #FFF;
    padding: 10rem;
    margin: 0 0 0 0;
}

.photos-component{
    color: #FFF;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    justify-items: center;
}

.fotos-div{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-content: center;
    align-items: center;
    justify-items: center;
}

.lista-component{
    background-color: #d8dcd3;
    color: #616D5F;
    text-align: center;
    padding-bottom: 5rem;
    display: grid;
    justify-items: center;
}

.lista-component h1, .photos-component h1{
    padding: 5rem 0 5rem 0;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
}

.lista-component h1{
    padding: 5rem 0 2rem 0 !important;
}


.btn-confirmar {
    background-color: #fff !important;
    color: #000 !important;
    border: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    padding: 16px !important;
    width: 100% !important;
    position: relative !important;
    transition: color 0.5s ease-out !important; 
    overflow: hidden !important;
    z-index: 0 !important;
    min-width: 280px;
    line-height: 20px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #616D5F !important;
    transition: color 0.5s ease-out; 
    display: flex;
    align-items: center;
    justify-content: center;
}

.btn-confirmar::after {
    background-color: #D8DCD3;
    content: '';
    display: block;
    width: 110%;
    height: 200%;
    position: absolute;
    left: 0%;
    top: 5rem;
    transform: translateY(0);
    transition: transform 0.5s ease-out;
    z-index: -1;
}

.btn-confirmar:hover{
    color: #000 !important;
}

.btn-confirmar:hover::after {
    transform: translate(-50%, -50%) scale(2); /* Aumenta a escala para 1 ao passar o mouse */
}

.buttons-confirm{
    height: 7rem !important;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    background: #616D5F;
}

@keyframes checkAnimation {
    0% {
        transform: scale(0) rotate(45deg);
    }
    50% {
        transform: scale(1.2) rotate(45deg);
    }
    100% {
        transform: scale(1) rotate(45deg);
    }
}

.check-animation {
    display: inline-block;
    width: 12px;
    height: 20px;
    border-bottom: 4px solid #3F8D43;
    border-right: 4px solid #3F8D43;
    animation: checkAnimation 0.5s ease forwards;
}

.animation-span{
    display: flex;
    justify-content: space-around;
    align-items: center;
    animation: subAnimation 0.5s ease forwards;
    font-family: "Barlow", sans-serif;
}

.x-animation {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
}

.x-animation::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ff0000;
    top: 50%;
    left: 0;
    transform-origin: center;
    transform: rotate(-41deg) !important;
    animation: xAnimation 0.5s ease forwards;
}

.x-animation::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 4px;
    background-color: #ff0000;
    top: 50%;
    left: 0;
    transform-origin: center;
    transform: rotate(-139deg) !important;
    animation: xAnimation 0.5s ease forwards;
}

@keyframes xAnimation {
    0% {
        transform: scale(0);
    }
    100% {
        transform: scale(1);
    }
}
.btn-confirmar-presenca{
    background: #3F8D43 !important;
    border: none !important;
}

.btn-nao-confirmar-presenca{
    background: #ff0000 !important;
    border: none !important;
}

.btn-confirmar-presenca:hover{
    background: #3f8d43d8 !important;
}

.btn-nao-confirmar-presenca:hover{
    background: #ff0000af !important;
}

.custom-modal-confirmacao-header, .custom-modal-confirmacao-body, .custom-modal-confirmacao-footer{
    text-align: center !important;
    justify-content: center !important;
    flex-wrap: nowrap;
}

.custom-modal-confirmacao-body{
    font-size: 1.1rem !important;
}

.btn-confirmar-presenca, .btn-nao-confirmar-presenca, .btn-cancelar{
    width: 12rem;
}

@media (max-width: 1536px) {
    .text p, h5 {
        font-size: 1.8rem;
    }
    .text h2 {
        font-size: 4.3rem;
    }
}

@media (max-width: 1300px) {
    .text p, h5 {
        font-size: 1.5rem;
    }
    .text h2 {
        font-size: 4rem;
    }
    .aviso-compra{
        width: 80%!important;
    }
}


@media (max-width: 1024px) {
    .btn-confirmar-presenca, .btn-nao-confirmar-presenca, .btn-cancelar{
        width: 8rem;
    }
    .div-horizon-pics{
        flex-direction: column;
    }
}

@media (max-width: 910px) {

    .text p, h5 {
        font-size: 1.5rem;
    }
    .text h2 {
        font-size: 3.5rem;
    }
    .btn-confirmar-presenca, .btn-nao-confirmar-presenca, .btn-cancelar{
        width: 8rem;
    }
}

@media (max-width: 800px) {
    .apresentation-component{
        flex-direction: column;
    }
    .fotos-div{
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .text p, h5 {
        font-size: 1.5rem;
    }
    .text h2 {
        font-size: 4rem;
    }
    .btn-confirmar-presenca, .btn-nao-confirmar-presenca, .btn-cancelar{
        width: 8rem;
    }
    .text{
        margin-top: 4rem;
        margin-bottom: 2rem;
    }
    .pick img {
        height: 35rem !important;
    }
}

@media (max-width: 524px) {
    .fotos-div{
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .btn-confirmar-presenca, .btn-nao-confirmar-presenca, .btn-cancelar{
        width: 8rem;
    }
}

@media (max-width: 500px) {

    .contador h3 {
        font-size: 1.3rem !important;
    }

}

@media (max-width: 480px) {
    .text h2 {
        font-size: 3.5rem;
    }
}

@media (max-width: 430px) {
    .text h2 {
        font-size: 3.2rem;
    }
    .pick img{
        height: auto !important;
    }
}

@media (max-width: 380px) {
    .text h2 {
        font-size: 3rem;
    }
}

.div-convidados {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.div-pai-convidados{
    display: grid;
    width: 50%;
    align-content: center;
    justify-content: center;
    padding-bottom: 1rem;
}

.form-convidados{
    display: flex;
    justify-content: center;
    flex-direction: column;
}

input[type="checkbox"] {
    appearance: none;
    background-color: #aaaaaa;
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;
    border: 2px solid #aaaaaa;
    transition: background-color 0.3s, border-color 0.3s;
    display: inline-block;
    vertical-align: middle;
}

input[type="checkbox"]:checked {
    background-color: #4CAF50;
    border-color: #4CAF50;
}

input[type="checkbox"]::after {
    content: '';
    position: absolute;
    width: 9px;
    height: 14px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg) scale(0);
    bottom: 50%;
    transform-origin: bottom left;
    opacity: 0;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

input[type="checkbox"]:checked::after {
    transform: rotate(45deg) scale(1);
    opacity: 1;
    transition: opacity 0.3s ease, transform 0.3s ease;
}

.custom-check{
    border-bottom: 4px solid #FFF !important;
    border-right: 4px solid #FFF !important;
}

.div-horizon-pics{
    display: flex;
    justify-content: center;
    align-items: center;
}

.aviso-compra{
    font-size: 1.2rem;
    width: 50%;    
    text-align: center;
}