body {
  margin: 0;
  scroll-behavior: smooth;
}

body::-webkit-scrollbar{
  width: 0.5rem;
  background-color: #D0D4CE;
}

body::-webkit-scrollbar-track {
  background: transparent;
}

body::-webkit-scrollbar-thumb {
  background-color: #949494;
  border-radius: 1rem;
  width: 1rem;
}

#root{
  overflow-x: hidden !important;

}
code {
}

#preloader .inner {
  position: absolute;
  top: 50%;
  /* centralizar a parte interna do preload (onde fica a animação)*/
  left: 50%;
  transform: translate(-50%, -50%);
}

.bolas>div {
  display: inline-block;
  background-color: #616D5F;
  width: 15px;
  height: 15px;
  border-radius: 100%;
  margin: 3px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: animarBola;
  animation-timing-function: linear;
  animation-iteration-count: infinite;

}

.bolas>div:nth-child(1) {
  animation-duration: 0.75s;
  animation-delay: 0;
}

.bolas>div:nth-child(2) {
  animation-duration: 0.75s;
  animation-delay: 0.12s;
}

.bolas>div:nth-child(3) {
  animation-duration: 0.75s;
  animation-delay: 0.24s;
}

@keyframes animarBola {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }

  16% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 0.7;
  }

  33% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.custom-icon{
  width: 4rem;
}

.container-fluid{
  --bs-gutter-x: 0rem!important;
}

@font-face {
  font-family: 'Brainly Script';
  src: url('../public/fonts/Brainly\ Script.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}