.desc-pick{
    padding: 5rem;
}

.desc-pick, .custom-pick {
    width: 50%;
    text-align: center;
}

.reverse{
    flex-direction: row-reverse;
    margin-bottom: -10rem;
}

.desc-pick h4{
    font-family: "Barlow", sans-serif;
    font-weight: 300;
    font-style: normal;
}

@media (max-width: 800px) {
    .text-and-pick{
        flex-direction: column !important;
    }

    .desc-pick, .custom-pick {
        width: 100% !important;
    }

    .desc-pick h4{
        font-size: 1.2rem;
    }

    .desc-pick{
        padding: 2rem;
    }

    /* .custom-pick img{
        width: 20rem;
        height: 20rem;
    } */
}