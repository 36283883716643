.mensagem{
    background-color: #616d5f;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    padding: 10rem;
    color: #fff;
    font-size: 2rem;
    text-align: center;
    justify-content: center;
    align-items: center;
    font-weight: 300;
    font-family: "Barlow", sans-serif;
}

.btn-pag-inicial {
    background-color: #fff !important;
    color: #616D5F !important;
    text-decoration: none;
    border: 0 !important;
    border: none !important;
    border-radius: 0 !important;
    cursor: pointer !important;
    padding: 0px !important;
    width: 100% !important;
    position: relative !important;
    transition: color 0.5s ease-out !important; 
    overflow: hidden !important;
    z-index: 0 !important;
    min-width: 280px;
    line-height: 20px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #616D5F !important;
    transition: color 0.5s ease-out; 
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1.5rem;
}

.btn-pag-inicial::after {
    background-color: #D8DCD3;
    content: '';
    display: block;
    width: 110%;
    height: 200%;
    position: absolute;
    left: 0%;
    top: 5rem;
    transform: translateY(0);
    transition: transform 0.5s ease-out;
    z-index: -1;
}

.btn-pag-inicial:hover{
    color: #000 !important;
}

.btn-pag-inicial:hover::after {
    transform: translate(-50%, -50%) scale(2); /* Aumenta a escala para 1 ao passar o mouse */
}

.redirect-to-home{
    color: #616D5F !important;
    text-decoration: none;
    padding: 16px!important;
    width: 100%;
    transition: color 0.5s ease-out !important; 
}

.redirect-to-home:hover{
    color: #000!important;
}
@media (max-width: 1200px){
    .mensagem{
       padding: 3rem;
       font-size: 1.5rem;
    }
}

@media (max-width: 800px){
     .mensagem{
        padding: 2rem;
     }
}

@media (max-width: 600px){
    .mensagem{
       padding: 2rem;
    }
}

@media (max-width: 400px){
    .mensagem{
       padding: 1rem;
    }
}

@media (max-width: 350px){
    .mensagem{
       padding: 1rem;
    }
}