.contador-component {
    display: flex;
    gap: 1rem;
}

.contador-item {
    background-color: #FFF;
    color: #616D5F;
    font-size: 1.75rem;
    border-radius: 2rem;
    padding: 1rem 3rem; /* Increased padding for more space */
    font-family: "Barlow", sans-serif;
    font-weight: 400;
    font-style: normal;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px; /* Adjusted width to accommodate increased padding */
    text-align: center;
}

.contador-number {
    margin-right: 0.5rem; /* Space between number and label */
}

@media (max-width: 1536px) {
    .contador-item {
        font-size: 1.5rem;
        padding: 1rem 2.5rem; /* Adjusted padding */
        width: 180px; /* Adjusted width */
    }
}

@media (max-width: 1400px) {
    .contador-item {
        font-size: 1.2rem;
        padding: 1.2rem 2.2rem; /* Adjusted padding */
        width: 160px; /* Adjusted width */
    }
}

@media (max-width: 910px) {
    .contador-item {
        font-size: 1.2rem;
        padding: 1.2rem 2.2rem; /* Keep padding same as above */
        width: 140px; /* Keep width same as above */
    }
}

@media (max-width: 650px) {
    .contador-component {
        gap: 0.5rem;
    }
    .contador-item {
        font-size: 1.2rem;
        padding: 1rem 2rem; /* Adjusted padding */
        width: 115px; /* Adjusted width */
    }
}

@media (max-width: 500px) {
    .contador-component {
        gap: 0.2rem;
    }
    .contador-item {
        font-size: 1rem;
        padding: 0.8rem 1.8rem; /* Adjusted padding */
        width: 95px; /* Adjusted width */
    }
}

@media (max-width: 400px) {
    .contador-component {
        gap: 0.1rem;
    }
    .contador-item {
        font-size: 0.95rem;
        padding: 0.8rem 1.8rem; /* Adjusted padding */
        width: 90px; /* Adjusted width */
    }
}