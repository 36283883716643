

  .container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .container .header {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-bottom: 40px;
  }
  
  .container .header span {
    font-size: 20px;
    line-height: 24px;
    padding-top: 15px;
  }
  
  .form-login {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 50%;
  }
  
  .form-login  .inputContainer {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
  }
  
  .form-login  .inputContainer label {
    font-size: 16px;
  }

  .form-login  .inputContainer {
    width: 100%;
  }
  
  .form-login  .inputContainer input {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    height: 3rem;
    border: 1px solid #616D5F;
    margin-bottom: 12px;
    border-radius: 0.5rem;
    padding: 1rem;
  }
  
  .form-login a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    padding-top: 16px;
    padding-bottom: 16px;
    color: #5C73DB;
  }
  
  .form-login .button {
    width: 520px;
    height: 48px;
    background: #616D5F;
    color: #fff;
    border: none;
    font-family: "Barlow", sans-serif !important;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
  }
  .form-login .button:hover {
    cursor: pointer;
    opacity: 0.8;
  }
  
  .form-login .footer {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px
  }
  
  .form-login .footer p {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
  }
  
  .form-login .footer a {
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-decoration-line: underline;
    color: #5C73DB;
  }

  .login-img{
    width: 15rem;
  }

  .div-login-img{
    width: 15rem;
  }
  
  @media (max-width: 962px) {
    .form-login {
      width: 100% !important;
    }
    .login-img{
      width: 13rem;
    }
  
    .div-login-img{
      width: 13rem;
    }
  }

  .form-login *{
    font-family: "Barlow", sans-serif;
    font-weight: 500;
  }

