.carrinho{
    background-color: #d8dcd3;
    text-align: center;
}

.carrinho h1{
    color: #FFF;
    padding: 5rem 0 5rem 0;
    font-family: "Barlow", sans-serif;
    font-weight: 400;
}

.color-466D62{
    color: #616D5F !important;
}

.custom-list-table{
    background: #fff;
    width: 100%;
    border-radius: 1rem;
    padding: 1rem;
    margin-bottom: 1rem;
    overflow: auto;
    height: 30rem !important;
    border: 2px solid;
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.listagem {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2rem 2rem 0 2rem;
}

.custom-th{
    font-size: 1.5rem!important;
    color: #000!important;
    background-color: #b6b4b4!important;
    padding: 0.3rem;
    margin: 1rem 1rem 1rem 1rem;
    width: 10rem;
}

.desc-table, .desc-itens{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;
    justify-content: center;
    align-content: center;
    overflow-x: hidden;
}

.titulo-item, .preco-item, .qtd-item, .qtd-item-modal{
    font-size: 1.2rem;
    margin: 1rem 0 1rem 0;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.qtd-item{
    display: flex;
    font-size: 1.1rem !important;
}

.qtd-item span{
    border: 1px solid #000;
    padding: 0 1rem 0 1rem;
}

.qtd-item button{
    border-radius: 0!important;
    padding: 0rem 1rem 0 1rem;
    background-color:#616D5F !important;
    font-weight: bold;
}

.qtd-item button:hover{
    background-color:#616D5Fc2 !important;
}

.aviso{
    font-size: 1rem;
    color: #000;
    line-height: 0;
    margin-bottom: 2rem;
    display: inline-block;
}

.aviso p{
    font-family: "Barlow", sans-serif;
    font-weight: 500;
}

.btn-carrinho{
    background-color: #fff !important;
    color: #616D5F !important;
    font-weight: bold !important;
    border: none !important;
}

.btn-carrinho:hover{
    background-color:#ffffffce !important;
    transition: 0.5s ease-in-out;
}

.btn-carrinho:focus{
    border:#616D5F !important;
}

.modal-content{
    padding: 1rem !important;
}

.qtd-item-modal{
    padding: 0 1rem 0 1rem;
}

.custom-modal-body{
    display: flex;
    justify-content: center;
}


.grey-3WMlEf.svelte-1a1oxv8.svelte-1a1oxv8 {
    color: #fff!important;
}

.p-modal-footer {
    text-align: center;
    width: 100%;
    border-bottom: var(--bs-modal-header-border-width) solid #616D5F !important;
    font-size: 1.5rem;
    padding-bottom: 1rem ;
}

.buttons-confirm{
    display: flex;
    flex-direction: column;
    padding: 0!important;
}

.btn-confirmar {
    background-color: #fff !important;
    color: #000 !important;
    border: 0 !important;
    border: none !important;
    cursor: pointer !important;
    padding: 16px !important;
    width: 100% !important;
    position: relative !important;
    transition: color 0.5s ease-out !important; 
    overflow: hidden !important;
    z-index: 0 !important;
    min-width: 280px;
    line-height: 20px !important;
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #616D5F !important;
    transition: color 0.5s ease-out; 
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: "Barlow", sans-serif !important;
}

.btn-confirmar::after {
    background-color: #D8DCD3;
    content: '';
    display: block;
    width: 110%;
    height: 200%;
    position: absolute;
    left: 0%;
    top: 5rem;
    transform: translateY(0);
    transition: transform 0.5s ease-out;
    z-index: -1;
}

.btn-confirmar:hover{
    color: #000 !important;
}

.btn-confirmar:hover::after {
    transform: translate(-50%, -50%) scale(2); /* Aumenta a escala para 1 ao passar o mouse */
}

.buttons-confirm{
    height: 7rem !important;
    display: grid;
    align-items: center;
    justify-content: center;
    align-content: center;
    background: #616D5F;
}

.custom-subtotal{
    font-size: 1.3rem;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.custom-subtotal span{
   font-weight: 500;
}

.desc-lista, .finalizacao {
    width: 50%;
}

.finalizacao .custom-list-table {
    background: #616D5F !important;
}

.finalizacao h1 {
    padding: 1rem 0 2rem 0 !important;
    border-bottom: 1px solid;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 2rem;
}

.finalizacao{
    color: #fff;
}

.finalizacao .desc-table{
    padding-top: 1rem;
}

.finalizacao .custom-list-table .desc-table .custom-th{
    background: #fff !important ;
}

.finalizacao .custom-list-table .desc-itens .titulo-item, 
.finalizacao .custom-list-table .desc-itens .preco-item,
.finalizacao .custom-list-table .desc-itens .qtd-item-modal{
    margin: 0 !important;
}

.custom-subtotal{
    padding: 1rem 0 1rem 0;
    border-top: 1px solid;
}

.finalizacao .all-items{
    padding-bottom: 2rem;
}

.custom-list-table::-webkit-scrollbar{
    width: 0.5rem;
    background-color: transparent;
    -webkit-box-shadow: inset 0 0 6px #fff;
}

.custom-list-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #fff;
    background: transparent;
    border-radius: 1rem;
}
  
.custom-list-table::-webkit-scrollbar-thumb {
    background-color: #616D5F;
    border-radius: 1rem;
    width: 1rem;
}

.finalizacao .custom-list-table::-webkit-scrollbar{
    width: 0.5rem;
    background-color: transparent;
    -webkit-box-shadow: inset 0 0 6px transparent ;
    border-radius: 2rem;
}

.finalizacao  .custom-list-table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px #616D5F;
    background-color: transparent;
    border-radius: 1rem;
}
  
.finalizacao  .custom-list-table::-webkit-scrollbar-thumb {
    background-color: #fff;
    border-radius: 1rem;
    width: 1rem;
}

.finalizacao .custom-list-table {
    border-top-right-radius: 1rem;
    border-bottom-right-radius: 1rem;
    border: 2px solid #000 !important;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}

.custom-th{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

.div-carrinho-vazio{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    padding-bottom: 5rem;

}

.carrinho-vazio a{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none !important;
}

.btn-vazio{
    min-width: 20rem !important;
    text-decoration: none !important;
    max-width: 100% !important;
}

.btn-vazio:hover{
    color: #fff !important;
}

.btn-vazio::after{
    background-color: #616D5F !important;
}

@media (max-width: 1200px) {
    .listagem{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .desc-lista, .finalizacao {
        width: 90%;
    }
    .custom-th {
        font-size: 1.2rem;
        padding: 0.3rem;
        margin: 0.5rem;
        width: 10rem;
    }

    .custom-list-table {
        border-radius: 0rem !important;
        margin-bottom: 0rem !important;
        border-right: 2px solid;
    }

    .aviso {
        line-height: 1.5;
        padding: 1rem;
    }
    .carrinho-vazio a{
        width: 20%;
    }
}

@media (max-width: 800px) {
    .listagem{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .desc-lista, .finalizacao {
        width: 95%;
    }
    .custom-th {
        font-size: 1.2rem;
        margin: 0.3rem !important;
        width: 10rem !important;
    }
    .titulo-item, .preco-item, .qtd-item, .qtd-item-modal {
        font-size: 1.1rem;
    }
    .qtd-item {
        font-size: 1.1rem !important;
    }
    .qtd-item button {
        padding: 0rem 0.5rem 0 0.5rem;
    }
    .qtd-item span {
        padding: 0rem 0.8rem 0 0.8rem;
    }
    .carrinho-vazio a{
        width: 30%;
    }

}

@media (max-width: 700px) {
    .custom-th {
        font-size: 1rem !important;
        margin: 0.3rem !important;
        width: 8rem !important;
    }
    .titulo-item, .preco-item, .qtd-item, .qtd-item-modal {
        font-size: 1rem;
    }
    .qtd-item {
        font-size: 1rem !important;
    }
    .qtd-item span {
        padding: 0rem 0.5rem 0 0.5rem;
    }
    .carrinho-vazio a{
        width: 30%;
    }

}

@media (max-width: 600px) {
    .listagem{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .desc-lista, .finalizacao {
        width: 95%;
    }
    .custom-th {
        font-size: 1rem !important;
        margin: 0.3rem !important;
        width: 7rem !important;
    }
    .carrinho-vazio a{
        width: 40%;
    }
}

@media (max-width: 400px) {
    .listagem{
        flex-direction: column;
        width: 100%;
        padding: 0;
    }
    .desc-lista, .finalizacao {
        width: 95%;
    }
    .custom-th {
        font-size: 1rem !important;
        margin: 0.1rem !important;
        width: 6.5rem !important;
    }
    .titulo-item, .preco-item, .qtd-item, .qtd-item-modal {
        font-size: 1rem;
    }
    .qtd-item {
        font-size: 1rem;
    }
    .carrinho-vazio a{
        width: 50%;
    }
    .btn-vazio{
        font-size: 0.9rem !important;
        min-width: 18rem !important;
    }
}

