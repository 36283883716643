.table-convidados{
    --bs-table-bg: #616D5F!important;
    --bs-table-color: #fff!important;
    text-align: center;
    height: 100vh;
    margin-bottom: 0rem!important;
}

.convidados-div{
    background-color: #616D5F;
}

.back-to-start{
    padding: 0.5rem;
    cursor: pointer;
}