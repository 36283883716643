.lista{
    background-color: #fff;
    width: 80%!important;
    border-radius: 1rem;
}

.lista-de-presentes{
    display: grid;
    align-items: center;
    justify-content: center;
    justify-items: center;
    padding: 5rem;
    grid-template-columns: repeat(4, 1fr);
}

@media (max-width: 1800px) {
    .lista{
        width: 90%!important
    }
}