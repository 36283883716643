.item{
    margin-bottom: 5rem;
}

.item-pic{
    border: 1px solid #000;
    padding: 2rem;
}

.item-pic img{
    width: 10rem;
    height: 10rem;
}

@keyframes subAnimation {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

.btn-lista {
    background-color: #b6b4b4 !important;
    color: #000 !important;
    border: none !important;
    max-width: 8rem;
    max-height: 2.5rem;
    min-width: 8rem;
    min-height: 2.5rem;
    position: relative;
    transition: color 0.5s ease-out; 
    overflow: hidden;
    z-index: 0;
}

.btn-lista::after {
    background-color: #616D5F; /* Cor de fundo ao passar o mouse */
    border-radius: 50%; /* Forma circular */
    content: '';
    display: block;
    width: 75%; /* Aumenta o tamanho para garantir que cobre todo o botão */
    height: 200%; /* Aumenta o tamanho para garantir que cobre todo o botão */
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%) scale(0); /* Começa a partir do centro com escala 0 */
    transition: transform 0.5s ease-out;
    z-index: -1;
}

.btn-lista:hover::after {
    transform: translate(-50%, -50%) scale(2); /* Aumenta a escala para 1 ao passar o mouse */
}

.btn-lista:hover {
    color: #fff !important; /* Cor do texto ao passar o mouse */
}

.btn-lista .animation-span span, .btn-lista .animation-span{
    font-family: "Barlow", sans-serif;
    font-weight: 500;
}
.animation-span{
    display: flex;
    justify-content: space-around;
    align-items: center;
    animation: subAnimation 0.5s ease forwards;
}

.buttons-lista button{
    margin: 0 1rem 0 0rem;
}

.item-info{
    color: #000;
    margin-top: 1rem;
}

.item-info h5, .item-info p{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
}

@keyframes checkAnimation {
    0% {
        transform: scale(0) rotate(45deg);
    }
    50% {
        transform: scale(1.2) rotate(45deg);
    }
    100% {
        transform: scale(1) rotate(45deg);
    }
}

.check-animation {
    display: inline-block;
    width: 12px;
    height: 20px;
    border-bottom: 4px solid #3F8D43;
    border-right: 4px solid #3F8D43;
    animation: checkAnimation 0.5s ease forwards;
}

.custom-link{
    line-height: 1.8rem !important;
}

.custom-cart-btn{
    width: 1rem;
    min-width: 3rem;
    border-radius: 0!important;
    background-color: #b6b4b4 !important;
    border-left: 1px solid !important;
}
 
.custom-buy-btn{
    margin: 0 !important;
    border-radius: 0 !important;
}

.hook-list{
    border-radius: 0!important;
}

.add-to-cart{
    font-size: 1.2rem;
    font-weight: 600;
}
@media (max-width: 1700px) {
    .lista-de-presentes{
        grid-template-columns: repeat(3, 1fr) !important;
    }
}

@media (max-width: 1500px) {
    .lista-de-presentes{
        grid-template-columns: repeat(3, 1fr) !important;
    }
    .lista{
        width: 90%!important
    }
}

@media (max-width: 1200px) {
    .buttons-lista button{
        margin: 0 0.5rem 0 0rem;
    }
}

@media (max-width: 1075px) {
    .lista-de-presentes{
        grid-template-columns: repeat(2, 1fr) !important;
    }
}

@media (max-width: 740px) {
    .lista-de-presentes{
        grid-template-columns: repeat(1, 1fr) !important;
    }
}

@media (max-width: 360px) {
    .btn-lista{
        margin: 0 0.4rem 0 0rem;
        min-width: 5rem;
    }
}