.header{
    display: flex;
    align-items: center;
    justify-content: center;
    color: #616D5F;
    padding: 1rem;
}

.span-cart{
    cursor: pointer;
}

.custom-nav-item{
    text-decoration: none !important;
    color: #616D5F !important;
    margin: 0 3rem 0 3rem;
    font-weight: 500;
    font-family: "Barlow", sans-serif;
}

.custom-nav-item a{
    text-decoration: none !important;
    color: #616D5F !important;
    font-size: 1.4rem;
    font-weight: 400;
    font-family: "Barlow", sans-serif;
}

.custom-nav-item a:hover{
    color: #000 !important;
    transition: 0.5s ease-in-out;
}

.custom-icon-logout{
    width: 1.2rem;
}

.cart{
    width: 2rem!important;
}
.custom-ul-navbar{
    margin: 0 10rem 0 10rem;
}


@media (max-width: 1300px) {
    .custom-ul-navbar{
        flex-direction: row!important;
    }
    .custom-nav-item{
        margin: 0 3rem 0 3rem !important;
    }
    .custom-ul-navbar{
        margin: 0 0rem 0 0rem;
    }
}

@media (max-width: 1024px) {
    .custom-ul-navbar{
        flex-direction: row;
    }
    .custom-nav-item{
        margin: 0 2rem 0 2rem !important;
    }
    .custom-ul-navbar{
        margin: 0 0rem 0 0rem !important;
    }
    .custom-ul-navbar{
        margin: 0 0rem 0 0rem !important;
    }
    .custom-btn-logout{
        display: contents !important;
    }
    .custom-icon-logout {
        width: 1rem;
    }
}

@media (max-width: 800px) {
    .custom-nav-item{
        margin: 0 1rem 0 1rem !important;
    }
}

@media (max-width: 768px) {
    .custom-ul-navbar{
        flex-direction: row;
    }
    .custom-nav-item{
        margin: 0 1rem 0 1rem !important;
    }
    .header{
        justify-content: space-between !important;
    }
    .span-cart #list img{
        width: 3rem !important;
    }
}


@media (max-width: 500px) {
    .custom-ul-navbar{
        flex-direction: row;
    }
    .custom-nav-item{
        margin: 0 0.4rem 0 0.4rem !important;
    }
}

.ul-offcanvas{
    flex-direction: column !important;
}

.ul-offcanvas .custom-nav-item{
    font-size: 1.2rem !important;
}

.btn-logout-offcanvas{
    background-color: #D8DCD3 !important;
    color: #000 !important;
    border: none !important;
    font-weight: 500;
    font-family: "Barlow", sans-serif;
}

.btn-logout-offcanvas:hover{
    background-color: #d4e7bcbb !important;
    color: #000 !important;
    border: none !important;
}