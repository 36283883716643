.endereco-div{
    display: flex;
    align-items: flex-end;
}

.desc-endereco{
    text-align: center;
    width: 90%;
    padding: 2rem;
    text-align: center;
    border: 1px solid #616D5F;
    border-radius: 1rem;
}

.desc-endereco h3 {
    font-family: "Barlow", sans-serif;
    font-weight: 500;
    font-style: normal;
}

.desc-endereco h5 {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

.custom-width{
    width: 50%!important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.buffet-pick{
    position: relative;
    left: 5rem;
}

.buffet-pick img{
    border-radius: 1rem;
    width: 20rem;
    height: 20rem;
}

#second-buffet-pick{
    position: absolute;
    right: 15rem;
    top: 5rem;
}

.icon-mapa, .icon-calendario{
    width: 2rem;
}

.block{
    display: block;
}

.infos-buffet{
    text-align: left;
    margin-top: 2rem;
}

.infos-buffet span{
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
}

@media (min-width: 1200px){
    .buffet-pick img {
        width: 20rem !important;
        height: 20rem !important;
    }
    .buffet-pick {
        left: 5rem !important;
        bottom: 0rem !important;
    }
}

@media (min-width: 1025px){
    .buffet-pick img {
        width: 15rem;
        height: 15rem;
    }
    .buffet-pick {
        left: 7rem;
        bottom: 2rem;
    }
    #second-buffet-pick {
        top: 6rem;
    }
}


@media (max-width: 1024px) {
    .endereco-div{
        flex-direction: column;
    }
    .custom-width {
        width: 100%!important;
        margin: 1rem 0 1rem 0 !important;
    }
    .buffet-pick {
        left: 7rem;
        bottom: 0rem !important;
    }
}


@media (max-width: 800px) {

    .buffet-pick {
        left: 7rem;
    }
    .buffet-pick img {
        width: 20rem;
        height: 20rem;
    }
    #second-buffet-pick {
        position: absolute;
        right: 15rem;
        top: 5rem;
    }
    .block{
        display: block;
        padding-top: 1rem;
    }
}

@media (max-width: 600px) {
    .endereco-div{
        flex-direction: column;
    }
    .custom-width {
        width: 100%!important;
        margin: 1rem 0 1rem 0 !important;
    }
    .buffet-pick {
        left: 5rem !important;
    }
    .buffet-pick img {
        width: 15rem !important;
        height: 15rem !important;
    }
    #second-buffet-pick {
        position: absolute;
        right: 12rem !important;
        top: 5rem !important;
    }
}

@media (max-width: 500px) {
    .buffet-pick img {
        width: 13rem !important;
        height: 13rem !important;
    }
    #second-buffet-pick {
        position: absolute;
        right: 10rem !important;
        top: 5rem !important;
    }
}

@media (max-width: 400px) {
    .buffet-pick img {
        width: 11rem !important;
        height: 11rem !important;
    }
    #second-buffet-pick {
        position: absolute;
        right: 10rem !important;
        top: 5rem !important;
    }
}

