.custom-images{
    border-radius: 1rem;
    width: 20rem;
    height: auto;
}

.div-images{
    margin: 2rem;
}

.custom-horizon-pics{
    width: 30rem!important;
}

@media (max-width: 800px) {
    .custom-horizon-pics{
        width: 23.4rem !important;
        max-width: 100% !important;
    }
    .custom-images {
        width: 18rem;
    }
    .div-images-hor{
        margin: 1rem!important;
    }
}